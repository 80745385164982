<template>
  <!-- Modal Autorizaciones - Formato 015-->
  <div v-if="tipo_autorizacion == 1">
    <div
      class="modal fade"
      id="modal-form-autorizacion-formato"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Autorización Descargue</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalAut"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label>Tipo Autorización</label>
            <v-select
              :class="[
                !slct_aut_desc ? 'is-invalid' : 'is-valid',
                $store.getters.getDarkMode ? 'dark-vselect' : '',
              ]"
              v-model="slct_aut_desc"
              label="descripcion"
              class="form-control form-control-sm p-0"
              :options="listasForms.autorizaciones_descargue"
              @input="form.justificacion = null"
            ></v-select>
          </div>
          <!-- Formulario Cambio de Vehículo -->
          <div
            class="modal-body"
            v-if="slct_aut_desc && slct_aut_desc.numeracion == 2"
          >
            <div class="row">
              <div class="form-group col-md-6">
                <label for="vehículo">Vehículo Actual</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="vehiculo"
                  readonly
                  v-model="form.vehiculoPlacaAnt"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="vehículo">Vehículo Sustituye</label>
                <v-select
                  :class="[
                    $v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.vehiculo"
                  placeholder="Placa"
                  label="placa"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.vehiculos"
                  @search="buscarVehiculos"
                  @input="validarTipoVehiculo"
                ></v-select>
                <div class="error" v-if="!$v.form.vehiculo.required">
                  Ingrese una Placa
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="justificacion">Justificacion</label>
                <textarea
                  v-model="form.justificacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.justificacion.required">
                  Ingrese una Justificación
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              slct_aut_desc &&
                slct_aut_desc.numeracion == 2 &&
                !$v.form.justificacion.$invalid &&
                !$v.form.vehiculo.$invalid
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="cambioVehiculo()"
            >
              Realizar Cambio
            </button>
          </div>
          <!-- Formulario Cambio de Remolque -->
          <div
            class="modal-body"
            v-if="slct_aut_desc && slct_aut_desc.numeracion == 3"
          >
            <div class="row">
              <div class="form-group col-md-6">
                <label for="remolque">Remolque Actual</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="remolque"
                  readonly
                  v-model="form.remolquePlacaAnt"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="vehículo">Remolque Sustituye</label>
                <v-select
                  :class="[
                    $v.form.remolque.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.remolque"
                  placeholder="Placa"
                  label="placa"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.remolques"
                  @search="buscarRemolques"
                ></v-select>
                <div class="error" v-if="!$v.form.remolque.required">
                  Ingrese una Placa
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="justificacion">Justificacion</label>
                <textarea
                  v-model="form.justificacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.justificacion.required">
                  Ingrese una Justificación
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              slct_aut_desc &&
                slct_aut_desc.numeracion == 3 &&
                !$v.form.justificacion.$invalid &&
                !$v.form.remolque.$invalid
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="cambioRemolque()"
            >
              Realizar Cambio
            </button>
          </div>
          <!-- Formulario Cambio Conductor -->
          <div
            class="modal-body"
            v-if="slct_aut_desc && slct_aut_desc.numeracion == 4"
          >
            <div class="row">
              <div class="form-group col-md-12">
                <label for="conductor">Conductor Actual</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="conductor"
                  readonly
                  v-model="form.conductorAnt"
                />
              </div>
              <div class="form-group col-md-12">
                <label for="conductor">Conductor Sustituye</label>
                <v-select
                  :class="[
                    $v.form.conductor.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.conductor"
                  placeholder="Nombre Conductor"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.conductores"
                  :filterable="false"
                  @search="buscarConductores"
                ></v-select>
                <div class="error" v-if="!$v.form.conductor.required">
                  Ingrese un Conductor
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="justificacion">Justificacion</label>
                <textarea
                  v-model="form.justificacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.justificacion.required">
                  Ingrese una Justificación
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              slct_aut_desc &&
                slct_aut_desc.numeracion == 4 &&
                !$v.form.justificacion.$invalid &&
                !$v.form.conductor.$invalid
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="cambioConductor()"
            >
              Realizar Cambio
            </button>
          </div>
          <!-- Formulario Cambio Ruta-->
          <div
            class="modal-body"
            v-if="slct_aut_desc && slct_aut_desc.numeracion == 5"
          >
            <div class="row">
              <div class="form-group col-md-6">
                <label for="ruta">Ruta actual</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="ruta"
                  readonly
                  v-model="form.rutaAnt"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="ruta">Ruta sustituye</label>
                <v-select
                  :class="[
                    $v.form.ruta.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.ruta"
                  placeholder="Ruta"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.rutas"
                  @search="buscarRutas"
                  @input="validarTarifaRuta"
                ></v-select>
                <div class="error" v-if="!$v.form.ruta.required">
                  Ingrese una ruta
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="justificacion">Justificación</label>
                <textarea
                  v-model="form.justificacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.justificacion.required">
                  Ingrese una Justificación
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              slct_aut_desc &&
                slct_aut_desc.numeracion == 5 &&
                !$v.form.justificacion.$invalid &&
                !$v.form.ruta.$invalid
            "
          >
            <button type="button" class="btn btn-primary" @click="cambioRuta()">
              Realizar Cambio
            </button>
          </div>
          <!-- Formulario Vencimiento de Guía | Novedades Digitación | Novedades Sellos | Omisión Punto de Control -->
          <div
            class="modal-body"
            v-if="
              (slct_aut_desc && slct_aut_desc.numeracion == 19) ||
                (slct_aut_desc && slct_aut_desc.numeracion == 20) ||
                (slct_aut_desc && slct_aut_desc.numeracion == 21) ||
                (slct_aut_desc && slct_aut_desc.numeracion == 22)
            "
          >
            <div class="row">
              <div class="form-group col-md-12">
                <label for="justificacion">Justificación</label>
                <textarea
                  v-model="form.justificacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.justificacion.required">
                  Ingrese una Justificación
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              slct_aut_desc &&
                (slct_aut_desc.numeracion == 19 ||
                  slct_aut_desc.numeracion == 20 ||
                  slct_aut_desc.numeracion == 21 ||
                  slct_aut_desc.numeracion == 22) &&
                !$v.form.justificacion.$invalid
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="cambioAutorizacion()"
            >
              Realizar Cambio
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Autorizacion Trinomios -->
  <div v-else>
    <div
      class="modal fade"
      id="modal-form-cambio-trinomios"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambio {{ entidad }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalAut"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- Formulario Vehículo -->
          <div class="modal-body" v-if="entidad && entidad == 'Vehículo'">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="vehículo">Vehículo Actual</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="vehiculo"
                  readonly
                  v-model="form.vehiculoPlacaAnt"
                />
              </div>
              <div
                class="form-group col-md-6"
                v-if="$parent.filtros.tipo_control == 1"
              >
                <label for="vehículo">Vehículo Sustituye</label>
                <v-select
                  :class="[
                    $v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.vehiculo"
                  placeholder="Placa"
                  label="placa"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.vehiculos"
                  @search="buscarVehiculos"
                  @input="validarTipoVehiculo"
                ></v-select>
                <div class="error" v-if="!$v.form.vehiculo.required">
                  Ingrese una Placa
                </div>
              </div>
              <div
                class="form-group col-md-6"
                v-if="$parent.filtros.tipo_control == 3"
              >
                <label for="vehiculo">Vehículo Sustituye</label>
                <input
                  type="text"
                  id="vehiculo"
                  class="form-control"
                  v-model="form.vehiculo"
                  @input="form.vehiculo = form.vehiculo.toUpperCase()"
                />

                <div class="error" v-if="!$v.form.vehiculo.required">
                  Ingrese una Placa
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="justificacion">Justificacion</label>
                <textarea
                  v-model="form.justificacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.justificacion.required">
                  Ingrese una Justificación
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              entidad &&
                entidad == 'Vehículo' &&
                !$v.form.justificacion.$invalid &&
                !$v.form.vehiculo.$invalid
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="cambioVehiculo()"
            >
              Realizar Cambio
            </button>
          </div>
          <!-- Formulario Remolque -->
          <div class="modal-body" v-if="entidad && entidad == 'Remolque'">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="remolque">Remolque Actual</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="remolque"
                  readonly
                  v-model="form.remolquePlacaAnt"
                />
              </div>
              <div
                class="form-group col-md-6"
                v-if="this.$parent.filtros.tipo_control == 1"
              >
                <label for="vehículo">Remolque Sustituye</label>
                <v-select
                  :class="[
                    $v.form.remolque.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.remolque"
                  placeholder="Placa"
                  label="placa"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.remolques"
                  @search="buscarRemolques"
                ></v-select>
                <div class="error" v-if="!$v.form.remolque.required">
                  Ingrese una Placa
                </div>
              </div>
              <div
                class="form-group col-md-6"
                v-if="this.$parent.filtros.tipo_control == 3"
              >
                <label for="vehículo">Remolque Sustituye</label>

                <input
                  type="text"
                  id="remolque"
                  class="form-control"
                  v-model="form.remolque"
                  @input="form.remolque = form.remolque.toUpperCase()"
                />

                <div class="error" v-if="!$v.form.vehiculo.required">
                  Ingrese una Placa
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="justificacion">Justificacion</label>
                <textarea
                  v-model="form.justificacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.justificacion.required">
                  Ingrese una Justificación
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              entidad &&
                entidad == 'Remolque' &&
                !$v.form.justificacion.$invalid &&
                !$v.form.remolque.$invalid
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="cambioRemolque()"
            >
              Realizar Cambio
            </button>
          </div>

          <!-- Formulario Empresa -->

          <div class="modal-body" v-if="entidad && entidad == 'Empresa'">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="empresa">Empresa Actual</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="empresa"
                  readonly
                  v-model="form.empresaAnt"
                />
              </div>
              <div
                class="form-group col-md-6"
              >
                <label for="empresa">Empresa Sustituye</label>
                <v-select
                  :class="[
                    $v.form.empresaNueva.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.empresaNueva"
                  placeholder="Empresa"
                  label="razon_social"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.empresas"
                  :reduce="(empresa) => empresa.id"
                ></v-select>
                <div class="error" v-if="!$v.form.empresaNueva.required">
                  Ingrese una Empresa
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="justificacion">Justificacion</label>
                <textarea
                  v-model="form.justificacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.justificacion.required">
                  Ingrese una Justificación
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              entidad &&
                entidad == 'Empresa' &&
                !$v.form.justificacion.$invalid/*  &&
                !$v.form.empresaNueva.$invalid */
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="cambiarEmpresa()"
            >
              Realizar Cambio
            </button>
          </div>
          <!-- Formulario Conductor -->
          <div class="modal-body" v-if="entidad && entidad == 'Conductor'">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="conductor">Conductor Actual</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="conductor"
                  readonly
                  v-model="form.conductorAnt"
                />
              </div>
              <div
                class="form-group col-md-12"
                v-if="this.$parent.filtros.tipo_control == 1"
              >
                <label for="conductor">Conductor Sustituye</label>
                <v-select
                  :class="[
                    $v.form.conductor.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.conductor"
                  placeholder="Nombre Conductor"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.conductores"
                  @search="buscarConductores"
                ></v-select>
                <div class="error" v-if="!$v.form.conductor.required">
                  Ingrese un Conductor
                </div>
              </div>
              <div
                class="form-group col-md-12"
                v-if="this.$parent.filtros.tipo_control == 3"
              >
                <label for="conductor">Conductor Sustituye</label>

                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="conductor"
                  placeholder="Cedula Conductor"
                  v-model="form.conductor_nuevo"
                />
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="conductor"
                  placeholder="Nombre Conductor"
                  v-model="form.nombre_conductor"
                />
              </div>
              <div class="form-group col-md-12">
                <label for="justificacion">Justificacion</label>
                <textarea
                  v-model="form.justificacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.justificacion.required">
                  Ingrese una Justificación
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              this.$parent.filtros.tipo_control == 1 &&
                entidad &&
                entidad == 'Conductor' &&
                !$v.form.justificacion.$invalid &&
                !$v.form.conductor.$invalid
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="cambioConductor()"
            >
              Realizar Cambio
            </button>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              this.$parent.filtros.tipo_control == 3 &&
                entidad &&
                entidad == 'Conductor' &&
                !$v.form.justificacion.$invalid &&
                !$v.form.conductor_nuevo &&
                !$v.form.nombre_conductor
            "
          >
            <button
              type="button"
              class="btn btn-primary"
              @click="cambioConductor()"
            >
              Realizar Cambio
            </button>
          </div>
          <!-- Formulario Ruta -->
          <div class="modal-body" v-if="entidad && entidad == 'Ruta'">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="ruta">Ruta actual</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="ruta"
                  readonly
                  v-model="form.rutaAnt"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="ruta">Ruta sustituye</label>
                <v-select
                  :class="[
                    $v.form.ruta.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.ruta"
                  placeholder="Ruta"
                  label="nombre"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.rutas"
                  @search="buscarRutas"
                  @input="validarTarifaRuta"
                ></v-select>
                <div class="error" v-if="!$v.form.ruta.required">
                  Ingrese una ruta
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="justificacion">Justificación</label>
                <textarea
                  v-model="form.justificacion"
                  cols="30"
                  rows="5"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                ></textarea>
                <div class="error" v-if="!$v.form.justificacion.required">
                  Ingrese una Justificación
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer p-1"
            v-if="
              entidad &&
                entidad == 'Ruta' &&
                !$v.form.justificacion.$invalid &&
                !$v.form.ruta.$invalid
            "
          >
            <button type="button" class="btn btn-primary" @click="cambioRuta()">
              Realizar Cambio
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "VentanaOperacionesCambios",

  components: {
    vSelect,
  },

  data() {
    return {
      viaje: null,
      slct_aut_desc: null,
      tipo_autorizacion: null,
      entidad: null,
      form: {
        vehiculo: null,
        vehiculo_id: null,
        vehiculoPlacaAnt: null,
        empresaAnt: null,
        conductor: null,
        nombre_conductor: null,
        conductor_id: null,
        conductorAnt: null,
        remolque: null,
        remolque_id: null,
        remolquePlacaAnt: null,
        ruta: null,
        ruta_id: null,
        rutaAnt: null,
        justificacion: null,
      },
      listasForms: {
        autorizaciones_descargue: [],
        conductores: [],
        vehiculos: [],
        remolques: [],
        rutas: [],
        empresas: [],
      },
    };
  },

  validations: {
    form: {
      vehiculo: {
        required,
      },
      remolque: {
        required,
      },
      conductor: {
        required,
      },
      ruta: {
        required,
      },
      justificacion: {
        required,
        minLength: minLength(10),
      },
      empresaNueva: {
        required
      }
    },
  },

  methods: {
    getIndex(viaje, tipo, entidad) {
      this.slct_aut_desc = null;
      this.viaje = viaje;
      this.tipo_autorizacion = tipo;
      this.entidad = entidad;
      this.cargarForm(this.viaje);
    },

    getListaAutorizaciones() {
      axios.get("/api/lista/193").then((response) => {
        this.listasForms.autorizaciones_descargue = [];
        response.data.forEach((aut) => {
          if (this.$store.getters.can(aut.cod_alterno)) {
            this.listasForms.autorizaciones_descargue.push(aut);
          }
        });
      });
    },

    cargarForm(viaje) {
      this.resetForm();
      this.form.viaje_id = viaje.id;

      if (this.$parent.filtros.tipo_control == 1) {
        this.form.guiaAnt = `${viaje.guia.numero}-${viaje.guia.digito_verificacion}`;
        this.form.guia_id = viaje.guia_id;
        this.form.ruta_id = viaje.ruta_id;
        this.form.rutaAnt = viaje.ruta.nombre;
        this.form.vehiculo_id = viaje.vehiculo_id;
        this.form.vehiculoPlacaAnt = viaje.vehiculo.placa;
        this.form.remolque_id = viaje.remolque_id;
        this.form.remolquePlacaAnt = viaje.remolque.placa;
        this.form.det_guia_id = viaje.guia.det_guias[0].id;
        this.form.conductor_id = viaje.conductor_id;
        this.form.conductor_documento = viaje.conductor.numero_documento;
        this.form.conductorAnt = `${viaje.conductor.numero_documento} - ${viaje.conductor.nombres} ${viaje.conductor.apellidos}`;
      } else if (this.$parent.filtros.tipo_control == 3) {
        this.form.conductorAnt = `${viaje.documento_conductor} - ${viaje.conductor}`;
        this.form.conductor_documento = viaje.documento_conductor;
        this.form.vehiculoPlacaAnt = viaje.vehiculo;
        this.form.remolquePlacaAnt = viaje.remolque;
        this.form.empresaAnt = viaje.transportadora.razon_social;
      }
    },

    resetForm() {
      this.form = {
        vehiculo: null,
        vehiculo_id: null,
        vehiculoPlacaAnt: null,
        conductor: null,
        conductor_id: null,
        conductorAnt: null,
        nombre_conductor: null,
        remolque: null,
        remolque_id: null,
        remolquePlacaAnt: null,
        ruta: null,
        ruta_id: null,
        rutaAnt: null,
        justificacion: null,
      };
    },

    buscarVehiculos(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/vehiculos/lista?placa?placa=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.vehiculos = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarRemolques(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/remolques/lista?placa=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.remolques = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarConductores(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/conductores/lista?numero_documento=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.conductores = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarRutas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "/api/admin/rutas/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.rutas = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    validarTipoVehiculo() {
      if (
        this.form.vehiculo &&
        this.form.vehiculo.tipo_vehiculo_id != this.viaje.tipo_vehiculo_id
      ) {
        this.$swal({
          icon: "error",
          title:
            "El tipo de vehículo de la placa seleccionada no corresponde al solicitado para este viaje, por favor seleccione vehículo.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.form.vehiculo = null;
      }
    },

    validarTarifaRuta() {
      if (this.form.ruta) {
        let me = this;
        const data = {
          ruta_id: this.form.ruta.id,
          tipo_vehiculo_id: this.viaje.tipo_vehiculo_id,
          det_guia: JSON.stringify(this.viaje.guia.det_guias[0]),
        };
        axios
          .get("/api/hidrocarburos/validar_tarifa", { params: data })
          .then((response) => {
            if (Object.keys(response.data).length === 0) {
              me.$swal({
                icon: "error",
                title:
                  "La ruta seleccionada no tiene una tarifa vigente, por favor seleccione otra.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.form.ruta = null;
            }
          });
      }
    },

    cambioVehiculo() {
      this.$swal({
        title: "Esta seguro de cambiar este vehículo?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarVehiculo?viaje_id=" +
                this.form.viaje_id,
              {
                tipo_autorizacion: this.tipo_autorizacion, // 1. Autorización Descargue - 2. Cambio de trinomio
                vehiculo_id_ant: this.form.vehiculo_id ?? null,
                vehiculo_id_nueva: this.form.vehiculo.id ?? null,
                vehiculo_placa_ant: this.form.vehiculoPlacaAnt ?? null,
                vehiculo_placa_nueva: this.form.vehiculo.placa,
                justificacion: this.form.justificacion,
                viaje_id: this.form.viaje_id,
                det_guia_id: this.form.det_guia_id,
                num_guia: this.form.guiaAnt,
                ruta: this.form.rutaAnt,
                remolque: this.form.remolquePlacaAnt,
                conductor: this.form.conductorAnt,
                estado_ant: this.viaje.estado,
                estadoViaje_ant: this.viaje.estadoViaje,
                tipo_control: this.$parent.filtros.tipo_control,
                placaNueva: this.form.vehiculo,
              }
            )
            .then((response) => {
              this.$refs.closeModalAut.click();
              this.$parent.getIndex();
              this.form = {};
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    cambioRuta() {
      this.$swal({
        title: "Esta seguro de cambiar este ruta?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarRuta?viaje_id=" +
                this.form.viaje_id,
              {
                tipo_autorizacion: this.tipo_autorizacion, // 1. Autorización Descargue - 2. Cambio de trinomio
                ruta_id_ant: this.form.ruta_id,
                ruta_id_nuevo: this.form.ruta.id,
                ruta_nombre_ant: this.form.rutaAnt,
                ruta_nombre_nuevo: this.form.ruta.nombre,
                justificacion: this.form.justificacion,
                viaje_id: this.form.viaje_id,
                det_guia_id: this.form.det_guia_id,
                num_guia: this.form.guiaAnt,
                vehiculo: this.form.vehiculoPlacaAnt,
                remolque: this.form.remolquePlacaAnt,
                conductor: this.form.conductorAnt,
                estado_ant: this.viaje.estado,
                estadoViaje_ant: this.viaje.estadoViaje,
              }
            )
            .then((response) => {
              this.$refs.closeModalAut.click();
              this.$parent.getIndex();
              this.form = {
                vehiculo: null,
                conductor: null,
                remolque: null,
                ruta: null,
                vehiculoPlacaAnt: null,
                remolquePlacaAnt: null,
                nombre_conductor: null,
                conductorAnt: null,
              };
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    cambiarEmpresa() {
      this.$swal({
        title: "Esta seguro de cambiar la empresa?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarEmpresa?viaje_id=" +
                this.form.viaje_id,
              {
                justificacion: this.form.justificacion,
                viaje_id: this.form.viaje_id,
                empresa: this.form.empresa,
                estado_ant: this.viaje.estado,
                estadoViaje_ant: this.viaje.estadoViaje,
                tipo_control: this.$parent.filtros.tipo_control,
                empresaNueva: this.form.empresaNueva,
              }
            )
            .then((response) => {
              this.$refs.closeModalAut.click();
              this.$parent.getIndex();
              this.form = {};
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    cambioRemolque() {
      this.$swal({
        title: "Esta seguro de cambiar este remolque?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarRemolque?viaje_id=" +
                this.form.viaje_id,
              {
                tipo_autorizacion: this.tipo_autorizacion, // 1. Autorización Descargue - 2. Cambio de trinomio
                remolque_id_ant: this.form.remolque_id,
                remolque_id_nueva: this.form.remolque.id,
                remolque_placa_ant: this.form.remolquePlacaAnt,
                remolque_placa_nueva: this.form.remolque.placa,
                justificacion: this.form.justificacion,
                viaje_id: this.form.viaje_id,
                det_guia_id: this.form.det_guia_id,
                num_guia: this.form.guiaAnt,
                ruta: this.form.rutaAnt,
                vehiculo: this.form.vehiculoPlacaAnt,
                conductor: this.form.conductorAnt,
                estado_ant: this.viaje.estado,
                estadoViaje_ant: this.viaje.estadoViaje,
                tipo_control: this.$parent.filtros.tipo_control,
                remolqueNuevo: this.form.remolque,
              }
            )
            .then((response) => {
              this.$refs.closeModalAut.click();
              this.$parent.getIndex();
              this.form = {
                vehiculo: null,
                conductor: null,
                remolque: null,
                ruta: null,
                vehiculoPlacaAnt: null,
                remolquePlacaAnt: null,
                nombre_conductor: null,
                conductorAnt: null,
              };
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    cambioConductor() {
      if (this.$parent.filtros.tipo_control == 1) {
        this.$swal({
          title: "Esta seguro de cambiar este conductor?",
          text: "No podra revertir esta acción",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Realizar el cambio!",
        }).then((result) => {
          if (result.value) {
            axios
              .put(
                "/api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarConductor?viaje_id=" +
                  this.form.viaje_id,
                {
                  tipo_autorizacion: this.tipo_autorizacion, // 1. Autorización Descargue - 2. Cambio de trinomio
                  conductor_id_ant: this.form.conductor_id,
                  conductor_documento_ant: this.form.conductor_documento,
                  conductor_nombre_ant: this.form.conductorAnt,
                  conductor_celular_ant: this.form.conductor_celular,
                  conductor_id_nueva: this.form.conductor.id,
                  conductor_documento_nuevo: this.form.conductor
                    .numero_documento,
                  conductor_nombre_nuevo: this.form.conductor.nombre,
                  conductor_celular_nuevo: this.form.conductor
                    .celular_principal,
                  justificacion: this.form.justificacion,
                  viaje_id: this.form.viaje_id,
                  det_guia_id: this.form.det_guia_id,
                  num_guia: this.form.guiaAnt,
                  ruta: this.form.rutaAnt,
                  vehiculo: this.form.vehiculoPlacaAnt,
                  remolque: this.form.remolquePlacaAnt,
                  estado_ant: this.viaje.estado,
                  estadoViaje_ant: this.viaje.estadoViaje,
                }
              )
              .then((response) => {
                this.$refs.closeModalAut.click();
                this.$parent.getIndex();
                this.form = {
                  vehiculo: null,
                  conductor: null,
                  remolque: null,
                  ruta: null,
                  vehiculoPlacaAnt: null,
                  remolquePlacaAnt: null,
                  conductorAnt: null,
                };
                this.$swal({
                  icon: "success",
                  title: response.data.mensaje,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      } else {
        this.$swal({
          title: "Esta seguro de cambiar este conductor?",
          text: "No podra revertir esta acción",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Realizar el cambio!",
        }).then((result) => {
          if (result.value) {
            axios
              .put(
                "/api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarConductor?viaje_id=" +
                  this.form.viaje_id,
                {
                  tipo_viaje: 2,
                  tipo_autorizacion: this.tipo_autorizacion, // 1. Autorización Descargue - 2. Cambio de trinomio
                  conductor_documento_ant: this.form.conductor_documento,
                  conductor_nombre_ant: this.form.conductorAnt,
                  conductor_nombre: this.form.nombre_conductor,
                  conductor_documento: this.form.conductor_nuevo,
                  justificacion: this.form.justificacion,
                  viaje_id: this.form.viaje_id,
                  det_guia_id: this.form.det_guia_id,
                  num_guia: this.form.guiaAnt,
                  estado_ant: this.viaje.estado,
                  estadoViaje_ant: this.viaje.estadoViaje,
                }
              )
              .then((response) => {
                this.$refs.closeModalAut.click();
                this.$parent.getIndex();
                this.form = {
                  vehiculo: null,
                  conductor: null,
                  remolque: null,
                  ruta: null,
                  nombre_conductor: null,
                  vehiculoPlacaAnt: null,
                  remolquePlacaAnt: null,
                  conductorAnt: null,
                };
                this.$swal({
                  icon: "success",
                  title: response.data.mensaje,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      }
    },

    cambioAutorizacion() {
      this.$swal({
        title: `Está seguro de modificar este ${this.slct_aut_desc.descripcion}?`,
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          axios
            .put(
              "/api/hidrocarburos/ventanasEnturnamientoOperacion/cambiarAutorizacion?viaje_id=" +
                this.form.viaje_id,
              {
                estado_ant: this.viaje.estado,
                estadoViaje_ant: this.viaje.estadoViaje,
                tipo_autorizacion: this.slct_aut_desc,
                conductor_id_ant: this.form.conductor_id,
                conductor_documento_ant: this.form.conductor_documento,
                conductor_nombre_ant: this.form.conductorAnt,
                conductor_celular_ant: this.form.conductor_celular,
                justificacion: this.form.justificacion,
                viaje_id: this.form.viaje_id,
                det_guia_id: this.form.det_guia_id,
                num_guia: this.form.guiaAnt,
                ruta: this.form.rutaAnt,
                vehiculo: this.form.vehiculoPlacaAnt,
                remolque: this.form.remolquePlacaAnt,
              }
            )
            .then((response) => {
              this.$refs.closeModalAut.click();
              this.$parent.getIndex();
              this.form = {
                vehiculo: null,
                conductor: null,
                remolque: null,
                ruta: null,
                nombre_conductor: null,
                vehiculoPlacaAnt: null,
                remolquePlacaAnt: null,
                conductorAnt: null,
              };
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    getEmpresas() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [2],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },
  },

  beforeMount() {
    this.getListaAutorizaciones();
    this.getEmpresas();
  },
};
</script>
